import React from 'react'
import { motion } from 'framer-motion';
import { opacity, expand } from './anim';
import styles from "./styles.module.css";

export default function Layout({ children, backgroundColor = "#000" }) {

  const anim = (variants, custom = null) => {
    return {
      initial: "initial",
      animate: "enter",
      exit: "exit",
      custom,
      variants
    }
  }

  const nbOfColumns = 5
  return (
    <div className='page stairs' style={{ backgroundColor }}>
      <motion.div {...anim(opacity)} className={styles['transition-background']} />
      <div className={styles['transition-container']}>
        {
          [...Array(nbOfColumns)].map((_, i) => {
            return (
              <motion.div key={i} {...anim(expand, nbOfColumns - i)} />
            )
          })
        }
      </div>
      {
        children
      }
    </div>
  )
}