import React from "react";
import styles from "./styles.module.css";
import transportimg from "../../assets/xbl_lynkit/transportimg.jpg";
import shipper_video from "../../assets/xbl_lynkit/shipper_video.mp4";
import Title from "../../helper_components/title";
import BodyText from "../../helper_components/body_text";

const XblLynkit = () => {
	return (
		// <div className={styles.main_container}>
		// 	<div className={styles.img}>
		// 		<img src={transportimg} alt="" />
		// 	</div>
		// 	<div className={styles.box_border_container1}>
		// 		<div className={styles["border-container"]}>
		// 			<div className={styles["inner-border"]}>
    //                     <div className={styles.contentHeading}>
    //                         What <span style={{'color':'#ff7700'}}>Lynkit XBL</span><br/> Can Do For Shippers & Freight Forwarders In India?
    //                     </div>
    //                     <div className={styles.contentPara}>
    //                         Freight procurement doesn't have to be complicated. Lynkit XBL simplifies the process by providing real-time insights, benchmarking tools, and predictive analytics designed to help you stay competitive and informed in the ever-evolving freight market whether you are a shipper using ocean freight or air, or a forwarder selling freight services to customers
    //                     </div>
    //                 </div>
		// 			<div className={styles["outer-border"]}></div>

		// 			{/* squares at each corner  */}
		// 			<div className={`${styles.corner} ${styles["top-left"]}`}></div>
		// 			<div className={`${styles.corner} ${styles["top-right"]}`}></div>
		// 			<div className={`${styles.corner} ${styles["bottom-left"]}`}></div>
		// 			<div className={`${styles.corner} ${styles["bottom-right"]}`}></div>

		// 			<div className={styles.connecting_lines}>
		// 				{/* Bottom connecting lines  */}
		// 				<div className={styles.bottom_left_to_top_right}></div>
		// 				<div className={styles.bottom_right_to_top_left}></div>
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>
		<div className={styles.main_container}>
			<div className={styles.video_container}>
				<video src={shipper_video} autoPlay loop muted playsInline />
			</div>
			<div className={styles.info_box}>
				<div className={styles.content_wrapper}>
					<Title text="What @Lynkit @XBL Can Do For Shippers & Freight Forwarders In India?" />
					<BodyText extraStyles={{textAlign: "center"}} text="Freight procurement doesn't have to be complicated. Lynkit XBL simplifies the process by providing real-time insights, benchmarking tools, and predictive analytics designed to help you stay competitive and informed in the ever-evolving freight market whether you are a shipper using ocean freight or air, or a forwarder selling freight services to customers" />
				</div>
			</div>
		</div>
	);
};

export default XblLynkit;
