import React from "react";
import Header from "./Components/Header/Header";
import mainStyle from "./main.module.css";
import HeroSection from "./Components/HeroSection/HeroSection";
import QuickGlance from "./Components/QuickGlance";
import Testimonial from "./Components/Testimonial";
import Features from "./Components/Features";
import UseCases from "./Components/UseCases";
import OtherFeature from "./Components/OtherFeature";
import Api from "./Components/API";
import Industries from "./Components/Industries";
import Hardware from "./Components/Hardware";
import OurAwards from "./Components/OurAwards";
import LatestNews from "./Components/LatestNews";
import Footer from "./Components/Footer";
import Reviews from "./Components/Reviews";
import Linkedin from "./Components/Linkedin";

const LandingPage = () => {
  const [selectedFeature, setSelectedFeature] = React.useState(0);
  const [selectedIndustry, setSelectedIndustry] = React.useState(0);

  return (
    <div className={mainStyle.landing_page} style={{overflowX: "hidden"}}>
      <Header setSelectedFeature={setSelectedFeature} />
      <div
        className={mainStyle.section_wrapper}
        style={{ position: "relative" }}
      >
        <section
          className={mainStyle.section}
          // style={{ height: "100vh" }}
        >
          <HeroSection />
        </section>
         <section
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative" }}
        >
          <QuickGlance />
        </section>
        <section
          id="features"
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative" }}
        >
          <Features setCurrent={setSelectedFeature} current={selectedFeature} />
        </section>
        <section
          className={mainStyle.section}
          style={{ background: "#fff", position: "relative", }}
        >
          <Testimonial />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Reviews />
        </section>
        <section
          id="useCases"
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <UseCases />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <OtherFeature setCurrent={setSelectedFeature} current={selectedFeature} />
        </section>
        <section
          id="hardware-products"
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Hardware />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
          id="api"
        >
          <Api />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Industries setCurrent={setSelectedIndustry} current={selectedIndustry} />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <OurAwards />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <LatestNews />
        </section>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Linkedin />
        </section>

      </div>
        <section
          className={mainStyle.section}
          style={{ position: "relative" }}
        >
          <Footer />
        </section>
    </div>
  );
};

export default LandingPage;
