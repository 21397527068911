import React from 'react'
import styles from "./styles.module.css";
import { motion, useInView } from 'framer-motion';

const Title = ({ text, extraStyles, extraClass = "" }) => {
  const splittedText = text.split(' '); // Split the text by spaces
 
  const pullupVariant = {
    initial: { y: 20, opacity: 0 },
    animate: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.6,
        delay: i * 0.2,
      },
    }),
  };

  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false });
  
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%", flexWrap: "wrap" }}>
      {splittedText.map((current, i) => {
        const isHighlighted = current.startsWith('@'); // Check if word starts with '@'
        
        const wordWithoutAt = isHighlighted ? current.slice(1) : current; // Remove '@' if highlighted
        
        return (
          <motion.span
            key={i}
            ref={ref}
            variants={pullupVariant}
            initial="initial"
            animate={isInView ? 'animate' : ''}
            custom={i}
            className={`${styles.title} ${extraClass}`}
            style={{
              ...extraStyles,
              color: isHighlighted ? '#ff7700' : 'white' 
            }}
          >
            {i === splittedText.length - 1 ? wordWithoutAt : <>{wordWithoutAt}<span>&nbsp;</span></>}
          </motion.span>
        );
      })}
    </div>
  );
}

export default Title;
