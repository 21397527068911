import React, { useState } from "react";
import styles from "./styles.module.css";
import Title from "../../helper_components/title";
import continental from "../../assets/testimonials/continental.svg";
import leftarrow from '../../assets/testimonials/leftarrow.png'
import rightarrow from '../../assets/testimonials/rightarrow.png'
import Slider from "../../helper_components/slider";
import nippon from '../../assets/testimonials/nippon.svg'
import food from '../../assets/testimonials/food.svg'
import styro from '../../assets/testimonials/styro.svg'

const testimonials = [
	{
		quote: "The Xeneta platform is my go-to sanity check for the ocean and air freight market. In addition, everybody recognizes Xeneta as a reliable source because it is far more comprehensive than other offerings.",
		name: "Marc Moss",
		position: "Group General Manager, Head of Global Key Account Sales, Nippon Express",
		logo: nippon,
	},
	{
		quote: "On one lane, I could see we were at the market high level. After further discussions with the carrier, I managed to get a 30% reduction on the rate, taking it from market high to just below market mid-low. On an annualized basis, this is worth close to $1.5M on one lane alone.",
		name: "Karen Goodall",
		position: "Global Category Leader, Freight & Logistics, Associated British Foods",
		logo: food,
	},
	{
		quote: "At Ineos Styrolution, we use the Xeneta platform daily. From time to time, we receive spot requests, and by using Xeneta, it is easy to gauge market conditions without even contacting carriers - reducing the amount of time spent on the process tremendously.",
		name: "Sebastian Wagner",
		position: "Regional Category Buyer, On & Off Site Logistics and Packaging Material",
		logo: styro,
	},
	{
		quote: "Xeneta's innovative platform has made it possible for us to adjust quickly to freight rate fluctuations, allowing us to save time and resources.",
		name: "Sarah Clarke",
		position: "Supply Chain Specialist, XYZ Corp",
		logo: continental,
	},
];

const TestimonialSlider = () => {

	return (
    <div className={styles.testimonials_outer_section}>
      <div className={styles.titleDiv}>
			  <Title text="Customer Testimonials" />
			</div>
      <div className={styles.testimonials_container}>
        <Slider slides={testimonials} />
      </div>
    </div>
	);
};

export default TestimonialSlider;
