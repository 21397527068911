import React, { useState, useEffect } from 'react';
import styles from "./styles.module.css";
import mainStyles from "../../main.module.css";
import { StyledHeading } from '../../HelperComponents';
import userManagmentIcon from '../../assets/otherFeature/userManagmentIcon.svg';
import userManagmentImage from '../../assets/otherFeature/userManagmentImage.svg';
import driverIcon from '../../assets/otherFeature/driverIcon.svg';
import driverImage from '../../assets/otherFeature/driverImage.svg';
import comprehensiveIcon from '../../assets/otherFeature/comprehensiveIcon.svg';
import comprehensiveAssetImage from '../../assets/otherFeature/comprehensiveAssetImage.svg';
import enhancedSecurityIcon from '../../assets/otherFeature/enhancedSecurityIcon.svg';
import enhancedSecurityImage from '../../assets/otherFeature/enhancedSecurityImage.svg'
import comprehensiveAnalyticsIcon from '../../assets/otherFeature/comprehensiveAnalyticsIcon.svg'
import comprehensiveAnalyticsImg from '../../assets/otherFeature/comprehensiveAnalyticsImg.svg'
import realTimeAlertIcon from '../../assets/otherFeature/realTimeAlertIcon.svg'
import realTimeAlertImg from '../../assets/otherFeature/realTimeAlertImg.svg'
import tripSharingIcon from '../../assets/otherFeature/tripSharingIcon.svg'
import tripSharingImg from '../../assets/otherFeature/tripSharingImg.svg'
import mapViewIcon from '../../assets/otherFeature/mapViewIcon.svg'
import mapViewImg from '../../assets/otherFeature/mapViewImg.svg'
import routefeatureIcon from '../../assets/otherFeature/routeFeatureIcon.svg'
import routefeatureImg from '../../assets/otherFeature/routeFeatureImg.svg'
import geofencesIcon from '../../assets/otherFeature/geofencesIcon.svg'
import geofencesImg from '../../assets/otherFeature/geofencesImg.svg'
import { SlideRightComponent } from '../../AnimationConents';
const Tab = ({ info, active, onClick }) => {
  return (
    <div
      className={styles.feature_tab}
      style={{
        background: active ? "#FF7700" : "#FFFFFF",
        color: active ? "#FFFFFF" : "#595959", 
        
      }}
      onClick={onClick}
    >
      <img
        src={info?.icon}
        style={{
          filter: active ? "invert(100%)" : "none" 
        }}
      />
      <span className={styles.title}>
        {info?.title}
      </span>
    </div>
  );
};


const OtherFeature = () => {
const [current, setCurrent]=useState(0)
const[animate, setAnimate]=useState(false)
  const otherfeatures = [
    {
      title: "User Management and Permissions",
      icon: userManagmentIcon,
      img: userManagmentImage,
      text: `Create multiple sub-users with specific roles and permissions, enabling factory and hub managers to control access. \n\nMark sealing/unsealing areas and warehouse locations and create specific routes for drivers.`
    },
    {
      title: "Driver and Vehicle Management",
      icon: driverIcon,
      img: driverImage,
      text: `Manage drivers by assigning them to vehicles and shipments, with performance reviews and secure KYC storage. \n\nTracks overspeeding, harsh driving, and other critical metrics with GPS accuracy and optional Bluetooth sensor integration. \n\nReal-time cargo tracking alerts with AI-powered ADAS + DMS Camera for collision, pedestrian warning, driver distractions, smoking, and calling detection.` 
    },
    {
      title: "Comprehensive Asset Tracking",
      icon: comprehensiveIcon,
      img: comprehensiveAssetImage,
      text: `Seamless cargo tracking -  trucks, trains, containers, tankers, and other movable assets using GPS, vision, and sensor-based IoT devices. \n\nProvides real-time visibility, route optimization, and detailed analytics for enhanced operational efficiency in cargo tracking. \n\nHistory tracking to view vehicle movement between specific dates with color-coded markers for events like overspeeding and over-stoppage. \n\nA comprehensive view of all live locations and full cargo tracking paths. \n\nSupports integration with ERPs, warehouse management, and transport management systems for seamless operations.`
    },
    {
      title: "Enhanced Security",
      icon: enhancedSecurityIcon,
      img: enhancedSecurityImage,
      text: `Use our portable and fixed GPS locks with features like tampering alerts, remote unlocking via web, Bluetooth, and SMS commands, and backup battery options. \n\nLow battery alarm, lock, unlock (in/out of geofence), tampered alarm, case open alert, overspeeding, over stoppage. Cargo tracking made easy! \n\nAI-powered cargo tracking cameras provide human detection, GPS tracking, and video recording to enhance cargo tracking and security. \n\nCustomizable alerts for events such as tampering, low battery, trip delays, and excessive stoppages.`
    },
    {
      title: "Comprehensive Analytics and Reporting",
      icon: comprehensiveAnalyticsIcon,
      img: comprehensiveAnalyticsImg,
      text: `Trip Analytics: Detailed performance metrics, including journey time, stoppage time, and moving speed against targets. \n\nEnterprise Dashboards: Customizable for route optimization, asset utilization, and performance analysis. \n\nAutomated, customizable reports for drivers, vehicles, and trips, with selectable data points tailored to specific needs.`
    },
    {
      title: "Real-time cargo tracking alerts and customized configuration",
      icon: realTimeAlertIcon,
      img: realTimeAlertImg,
      text: `Get real-time cargo tracking alerts via SMS, email, and push notifications. \n\nTurn alerts on or off as per your needs with flexible alert settings. \n\nCustomize alert templates with your logo and business branding. \n\nDetect consignment & fleet separation.`
    },
    {
      title: "Trip Sharing and Analysis",
      icon: tripSharingIcon,
      img: tripSharingImg,
      text: `Share the countless live trips. \n\nPre-defined link expire time. \n\nDirectly mail the Live trip with a user. \n\nLive trip Links can be generated for surveillance. \n\nTrip-wise analytics show total journey time, moving/stoppage times, and their % split. `
    },
    {
      title: "Map View",
      icon: mapViewIcon,
      img: mapViewImg,
      text: `Get real-time cargo tracking for each trip and an event-wise summary line graph in map view for quick checks. \n\nGet access to map-based notifications. \n\nThe map UI indicates points where there have been blind spots, i.e., no GPS data, to allow users to make better decisions for route planning.`
    },
    {
      title: "Route Features",
      icon: routefeatureIcon,
      img: routefeatureImg,
      text: `Create a specific route for users that you want the driver to follow. Sources, Via Points, and Destinations can be mapped and added. \n\nUsers can set the trip's start, end, & rest time for the trip. \n\nThe system will auto-check & suggest the best route according to the vehicle capacity.  \n\nUsers can set the slot for delivering the products. \n\nThe end customer will receive the notification once their order is out. \n\nReal-time cargo tracking for the end customer. `
    },

    {
      title: "Geofences",
      icon: geofencesIcon,
      img: geofencesImg,
      text: `These features help users mark their sealing area/unsealing area, warehouse location, or destination location on the map. This feature will update you on whether your GPS device is inside or outside your marked area. \n\nSet the Geofence for the locations by inputting the location name or adding lat and long.`
    }
  ];

  useEffect(() => {
    if (animate) {
      const timer = setTimeout(() => {
        setAnimate(false);
      }, 1000); // Match this duration with your CSS transition duration

      return () => clearTimeout(timer);
    }
  }, [animate]);


  const handleTabClick=(i)=>{
    setCurrent(i);
    setAnimate(true)

  }

  return (
    <div className={styles.software_features_section}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledHeading text="Other Features" />
      </div>
      <div className={`${styles.features_outer_container} ${styles.large_screens}`}>
        <div className={styles.feature_tabs}>
          {otherfeatures?.map((feature, i) => {
            return (
              <Tab
                info={feature}
                active={i === current ? true : false}
                onClick={() => handleTabClick(i)}
                key={i}
              />
            );
          })}
        </div>

        <div className={styles.feature_content}>
          <div className={styles.text_content}>
            <span>{otherfeatures[current].title}</span>
            <p>{otherfeatures[current].text}</p>
          </div>
          <div className={styles.img_content}>
            {animate?
            <SlideRightComponent duration={1000}>
                <img src={otherfeatures[current].img} />
            </SlideRightComponent>
           :
           <img src={otherfeatures[current].img} />
           }
          </div>
        </div>

      </div>
      <div className={`${styles["slider-container"]} ${styles.small_screens}`}>
        <div className={`${styles["slider-wrapper"]}`}>
          {otherfeatures?.map((feature, i) => {
            return (
              <div className={`${styles["slider-item"]}`}>
                <div className={styles["title-row"]}>
                  <img src={feature.icon} />
                  <h3>{feature.title}</h3>
                </div>
                <hr style={{border: "1px solid #ff7700", opacity: 1}} />
                <p style={{fontSize: "14px"}}>{feature.text}</p>
                <img src={feature?.img} alt="Geofencing" />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default OtherFeature