import React, { useState } from 'react';
import styles from "./style.module.css";
import Title from '../helper_components/title';
import Particle from '../helper_components/particle';
import planeOrange from "../assets/lane/planeOrange.svg";
import planeWhite from "../assets/lane/planeWhite.svg";
import shipOrange from "../assets/lane/ShipOrange.svg";
import shipWhite from "../assets/lane/shipWhite.svg";
import bothOrange from "../assets/lane/bothOrange.svg";
import bothWhite from "../assets/lane/bothWhite.svg";
import useApi from '../useApi';
import { activeUrl } from '../config';
import CustomToast from '../../../CustomToast';
import Header from '../components/header';
import Footer from '../components/footer';

const ContactUsXBL = () => {
  const [userType, setUserType] = useState("shipper");
  const [mode, setMode] = useState("air");
  const [selectedLane, setSelectedLane] = useState(5);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });
  const [toast, setToast] = useState({ message: "", type: "" });

  const { callApi, loading } = useApi((message, type) => {
    setToast({ message, type });
    setTimeout(() => setToast({ message: "", type: "" }), 5000);
  });

  const functions = [
    {
      name: "Shipper",
      id: "shipper",
    },
    {
      name: "Freight Forwarder",
      id: "freightForwarder",
    },
  ]

  const modelData = [
    {
      text: "Air Freight",
      id: "air",
      icon: planeOrange,
      whiteIcon: planeWhite,
    },
    {
      text: "Ocean Freight",
      id: "ocean",
      icon: shipOrange,
      whiteIcon: shipWhite,
    },
    {
      text: "Both",
      id: "both",
      icon: bothOrange,
      whiteIcon: bothWhite,
    },
  ];

  const numberOfLanes = React.useMemo(() => {
    if (userType) {
      if (userType === "shipper") {
        setSelectedLane(5);
        return [5, 10, 20];
      } else {
        setSelectedLane(20);
        return [20, 40, 60];
      }
    } else {
      return [];
    }
  }, [userType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};

    // Validate Name
    if (!formData.companyName.trim()) {
      newErrors.companyName = "Company Name is required.";
    }

    // Validate First Name
    const alphabetRegex = /^[a-zA-Z ]+$/;
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required.";
    } else if (!alphabetRegex.test(formData.firstName)) {
      newErrors.firstName = "Enter a valid first name.";
    }

    // Validate Last Name - optional field
    if (formData.lastName.trim() && !alphabetRegex.test(formData.lastName)) {
      newErrors.lastName = "Enter a valid last name.";
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    // Validate mobile
    const mobileRegex = /^\d{10}$/;
    if (!formData.mobile) {
      newErrors.mobile = "Phone Number is required.";
    } else if (!mobileRegex.test(formData.mobile)) {
      newErrors.mobile = "Enter a valid phone number.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userType || !mode || !selectedLane) {
      alert("Please select all options.");
      return;
    }

    if (validate()) {
      const payload = {
        ...formData,
        userType: userType, // possible values are- 'shipper', or 'freightforwarder'
        freightMode: mode, // possible values are- "ocean" or "air" or "both" 
        noOfLanes: selectedLane, // possible values are- for shipper:  5, 10, 20; for freightforwarder: 20, 40, 60
      }

      const response = await callApi({
        url: `${activeUrl}/user/query`,
        method: "POST",
        data: payload,
      });

      if (response.success) {
        setFormData({
          companyName: "",
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
        setUserType("shipper");
        setMode("air");
        setSelectedLane(5);
      } else {
        // console.error("API call failed", response.error);
      }
    } else {
      // console.log("Validation failed:", errors);
    }
  };

  return (
    <>
      <div className={styles.contact_us_container}>
        <Header />
        <div className={styles.particle_bg}>
          <Particle />
        </div>
        <div className={styles.contact_us_inner}>
          <Title text="Contact Us" />
          <div className={styles.contact_box}>
            {/* user form */}
            <div className={styles.form_box}>
              <div className={styles.contact_form_row}>
                <div className={styles.formField}>
                  <label>Company Name*</label>
                  <input
                    type="text"
                    name="companyName"
                    value={formData.companyName}
                    onChange={handleChange}
                    placeholder='Enter Company Name'
                  />
                  {errors.companyName && <p style={{ color: "red", fontSize: "12.5px" }}>{errors.companyName}</p>}
                </div>
              </div>
              <div className={styles.contact_form_row}>
                <div className={styles.formField}>
                  <label>First Name*</label>
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder='Enter First Name'
                  />
                  {errors.firstName && <p style={{ color: "red", fontSize: "12.5px" }}>{errors.firstName}</p>}
                </div>
                <div className={styles.formField}>
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder='Enter Last Name'
                  />
                  {errors.lastName && <p style={{ color: "red", fontSize: "12.5px" }}>{errors.lastName}</p>}
                </div>
              </div>
              <div className={styles.contact_form_row}>
                <div className={styles.formField}>
                  <label>Email*</label>
                  <input
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder='Enter Email Id'
                  />
                  {errors.email && <p style={{ color: "red", fontSize: "12.5px" }}>{errors.email}</p>}
                </div>
                <div className={styles.formField}>
                  <label>Phone*</label>
                  <input
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    placeholder='Enter Phone Number'
                  />
                  {errors.mobile && <p style={{ color: "red", fontSize: "12.5px" }}>{errors.mobile}</p>}
                </div>
              </div>
            </div>

            {/* function */}
            <div className={styles.controls_section}>
              <span>Your Function</span>
              <div className={styles.horizontalLine} />
              <div className={styles.function_controls}>
                {functions.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`${styles.control_btn} ${item?.id === userType ? styles.active_btn : ""}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setUserType(item?.id)
                      }}>
                      {item?.name}
                    </div>
                  )
                })}
              </div>
            </div>

            {/* mode */}
            <div className={styles.controls_section}>
              <span>Freight Mode</span>
              <div className={styles.horizontalLine} />
              <div className={styles.function_controls}>
                {modelData.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`${styles.control_btn} ${item?.id === mode ? styles.active_btn : ""}`}
                      onClick={(e) => {
                        e.preventDefault();
                        setMode(item?.id);
                      }}
                    >
                      {mode === item.id ? (
                        <img src={item.whiteIcon} style={{ height: "25px", width: "25px", marginRight: "15px" }} />
                      ) : (
                        <img
                          src={item.icon}
                          style={{ height: "25px", width: "25px", marginRight: "15px" }}
                        />
                      )}
                      {item?.text}
                    </div>
                  )
                })}
              </div>
            </div>

            {/* lanes */}
            <div className={styles.controls_section}>
              <span>Number of Lanes</span>
              <div className={styles.horizontalLine} />
              <div className={`${styles.function_controls} ${styles.lanes_radios}`} style={{ maxWidth: "200px", paddingLeft: "5px" }}>
                {numberOfLanes.map((lane) => (
                  <label
                    key={lane}
                    className={styles.allRadioOption}
                  >
                    <input
                      type="radio"
                      name="lanes"
                      value={lane}
                      checked={selectedLane === lane}
                      onChange={() => setSelectedLane(lane)}
                    />
                    {lane}
                  </label>
                ))}
              </div>
            </div>

            {/* submit */}
            <button className={styles.submit_btn} disabled={loading} onClick={handleSubmit}>
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
        <CustomToast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast({ message: "", type: "" })}
        />
        {/* <section
          style={{ position: 'relative', width: "100%", overflow: "hidden", background: "#000" }}
        >
          <Footer />
        </section> */}
      </div>
    </>
  )
}

export default ContactUsXBL