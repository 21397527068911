import React from "react";
import styles from "./styles.module.css";
import mobileStyles from "./mobileChip.module.css";
import bigBgImg from "../../assets/userCases/user_cases_desktop.svg";
import smallBgImg from "../../assets/userCases/user_cases_mobile.svg";
import frame1_mobile from "../../assets/userCases/mob_frame1.svg";
import frame2_mobile from "../../assets/userCases/mob_frame2.svg";
import { StyledHeading } from "../../HelperComponents";

const UseCases = () => {
  const [activeId, setActiveId] = React.useState(null);

  const handleChipClick = (id) => {
    if (activeId && id === activeId) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };

  return (
    <>
      {/* Desktop view */}
      <div className={`${styles.outer_container} ${styles.desktop_view}`}>
        <div className={styles.heading}>
          <StyledHeading text="Use Cases" light={true} />
        </div>
        <img src={bigBgImg} className={styles.desktop_bg} />
        <img src={smallBgImg} className={styles.mobile_bg} />
        {/* Title pills---------- */}
        <div
          className={styles.title_pill}
          id={styles.buses}
          onClick={() => handleChipClick("buses")}
        >
          <span>Buses</span>
          <div
            id={`${styles.buses_htl}`}
            className={`${styles.dashed_lines} ${activeId === "buses" ? styles.active : ""}`}
          >
            <div
              id={`${styles.buses_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "buses" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.buses_details}>
                Tracking available for all passengers, including real time ETAs
                for bus stops as well as automated attendance counting
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.vans}
          onClick={() => handleChipClick("vans")}
        >
          <span>Vans Tracking</span>
          <div
            id={`${styles.vans_htl}`}
            className={`${styles.dashed_lines} ${activeId === "vans" ? styles.active : ""}`}
          >
            <div
              id={`${styles.vans_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "vans" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.vans_details}>
                Fortify security for cash vans and shipments with our GPS Locks,
                hidden cameras, and buzzers with live 4 G-based streaming. Our
                in-vehicle tablets offer great
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.field_staff}
          onClick={() => handleChipClick("field_staff")}
        >
          <span>Field Staff</span>
          <div
            id={`${styles.field_staff_htl}`}
            className={`${styles.dashed_lines} ${activeId === "field_staff" ? styles.active : ""}`}
          >
            <div
              id={`${styles.field_staff_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "field_staff" ? styles.active : ""}`}
            >
              <div
                className={styles.detail_box}
                id={styles.field_staff_details}
              >
                Empower supply chain teams with tailored tracking on our
                SaaS-based platform. Enhance 2-wheeler staff safety and
                in-facility operations with BLE wristbands, facilitating
                movement, access, and productivity monitoring.
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.forklift}
          onClick={() => handleChipClick("forklift")}
        >
          <span>Forklift Tracking</span>
          <div
            id={`${styles.forklift_htl}`}
            className={`${styles.dashed_lines} ${activeId === "forklift" ? styles.active : ""}`}
          >
            <div
              id={`${styles.forklift_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "forklift" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.forklift_details}>
                Elevate safety in supply chain operations using our forklift
                monitoring tool, which eliminates blind spots via wireless
                cameras and prevents accidents through AI-based human detection
                cameras and proximity sensors.
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.chain}
          onClick={() => handleChipClick("chain")}
        >
          <span>Cold Chain</span>
          <div
            id={`${styles.chain_htl}`}
            className={`${styles.dashed_lines} ${activeId === "chain" ? styles.active : ""}`}
          >
            <div
              id={`${styles.chain_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "chain" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.chain_details}>
                We have you covered, whether it is a reefer or a truck! Our
                wired and wireless sensors offer zone-wise temperature
                detection. We even have PLC integration options available for
                you to get access to remote temperature control and
                configuration.
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.boxes}
          onClick={() => handleChipClick("boxes")}
        >
          <span>Container/Boxes</span>
          <div
            id={`${styles.boxes_htl}`}
            className={`${styles.dashed_lines} ${activeId === "boxes" ? styles.active : ""}`}
          >
            <div
              id={`${styles.boxes_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "boxes" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.boxes_details}>
            Ensure seamless cargo tracking with our various portable and battery-powered asset trackers, which can be hidden inside boxes or attached to containers.
            </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.bikes}
          onClick={() => handleChipClick("bikes")}
        >
          <span>Bikes</span>
          <div
            id={`${styles.bikes_htl}`}
            className={`${styles.dashed_lines} ${activeId === "bikes" ? styles.active : ""}`}
          >
            <div
              id={`${styles.bikes_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "bikes" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.bikes_details}>
                Simplify last-mile deliveries and first-mile pick-ups with our
                bike trackers, which sync up with our route optimisers and ETA
                engines.
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.railway}
          onClick={() => handleChipClick("railway")}
        >
          <span>Railway Wagons</span>
          <div
            id={`${styles.railway_htl}`}
            className={`${styles.dashed_lines} ${activeId === "railway" ? styles.active : ""}`}
          >
            <div
              id={`${styles.railway_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "railway" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.railway_details}>
                Effortlessly monitor railway wagons across the supply chain
                using our mega power or solar-powered trackers not requiring
                charging or power for up to 5 years.
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.title_pill}
          id={styles.trucks}
          onClick={() => handleChipClick("trucks")}
        >
          <span>Trucks</span>
          <div
            id={`${styles.trucks_htl}`}
            className={`${styles.dashed_lines} ${activeId === "trucks" ? styles.active : ""}`}
          >
            <div
              id={`${styles.trucks_vtl}`}
              className={`${styles.dashed_lines} ${activeId === "trucks" ? styles.active : ""}`}
            >
              <div className={styles.detail_box} id={styles.trucks_details}>
                The next best after autonomous is hyper-connected vehicles.
                Control and secure your supply chain truck fleet with our GPS
                trackers, locks, cargo hold cameras, fuel sensors, ADAS and DMS
                camera systems, and voice boxes for driver communication.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ----- Mobile view ----- */}
      <div className={`${styles.mobile_container} ${styles.mobile_view}`}>
        <div className={mobileStyles.heading_mb}>
          <StyledHeading text="Use Cases" light={true} />
        </div>
        <div className={`${styles.mobile_sec} ${styles.frame1}`}>
          <img src={frame1_mobile} />
          <div
            className={styles.title_pill}
            id={mobileStyles.forklift_mb}
            onClick={() => handleChipClick("forklift")}
          >
            <span>Forklift Tracking</span>
            <div
              id={`${mobileStyles.forklift_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "forklift" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.forklift_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "forklift" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.forklift_details_mb}>
                  Elevate safety in supply chain operations using our forklift
                  monitoring tool, which eliminates blind spots via wireless
                  cameras and prevents accidents through AI-based human detection
                  cameras and proximity sensors.
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.bikes_mb}
            onClick={() => handleChipClick("bikes")}
          >
            <span>Bikes</span>
            <div
              id={`${mobileStyles.bikes_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "bikes" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.bikes_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "bikes" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.bikes_details_mb}>
                  Simplify last-mile deliveries and first-mile pick-ups with our
                  bike trackers, which sync up with our route optimisers and ETA
                  engines.
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.field_staff_mb}
            onClick={() => handleChipClick("field_staff")}
          >
            <span>Field Staff</span>
            <div
              id={`${mobileStyles.field_staff_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "field_staff" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.field_staff_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "field_staff" ? styles.active : ""}`}
              >
                <div
                  className={styles.detail_box}
                  id={mobileStyles.field_staff_details_mb}
                >
                  Empower supply chain teams with tailored tracking on our
                  SaaS-based platform. Enhance 2-wheeler staff safety and
                  in-facility operations with BLE wristbands, facilitating
                  movement, access, and productivity monitoring.
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.chain_mb}
            onClick={() => handleChipClick("chain")}
          >
            <span>Cold Chain</span>
            <div
              id={`${mobileStyles.chain_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "chain" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.chain_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "chain" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.chain_details_mb}>
                  We have you covered, whether it is a reefer or a truck! Our
                  wired and wireless sensors offer zone-wise temperature
                  detection. We even have PLC integration options available for
                  you to get access to remote temperature control and
                  configuration.
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.trucks_mb}
            onClick={() => handleChipClick("trucks")}
          >
            <span>Trucks</span>
            <div
              id={`${mobileStyles.trucks_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "trucks" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.trucks_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "trucks" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.trucks_details_mb}>
                  The next best after autonomous is hyper-connected vehicles.
                  Control and secure your supply chain truck fleet with our GPS
                  trackers, locks, cargo hold cameras, fuel sensors, ADAS and DMS
                  camera systems, and voice boxes for driver communication.
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.boxes_mb}
            onClick={() => handleChipClick("boxes")}
          >
            <span>Container/Boxes</span>
            <div
              id={`${mobileStyles.boxes_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "boxes" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.boxes_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "boxes" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.boxes_details_mb}>
                  Ensure seamless cargo tracking with our various portable and battery-powered asset trackers, which can be hidden inside boxes or attached to containers.
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className={`${styles.mobile_sec} ${styles.frame2}`}>
          <img src={frame2_mobile} />
          <div
            className={styles.title_pill}
            id={mobileStyles.railway_mb}
            onClick={() => handleChipClick("railway")}
          >
            <span>Railway Wagons</span>
            <div
              id={`${mobileStyles.railway_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "railway" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.railway_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "railway" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.railway_details_mb}>
                  Effortlessly monitor railway wagons across the supply chain
                  using our mega power or solar-powered trackers not requiring
                  charging or power for up to 5 years.
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.vans_mb}
            onClick={() => handleChipClick("vans")}
          >
            <span>Vans Tracking</span>
            <div
              id={`${mobileStyles.vans_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "vans" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.vans_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "vans" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.vans_details_mb}>
                  Fortify security for cash vans and shipments with our GPS Locks,
                  hidden cameras, and buzzers with live 4 G-based streaming. Our
                  in-vehicle tablets offer great
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.title_pill}
            id={mobileStyles.buses_mb}
            onClick={() => handleChipClick("buses")}
          >
            <span>Buses</span>
            <div
              id={`${mobileStyles.buses_htl_mb}`}
              className={`${styles.dashed_lines} ${activeId === "buses" ? styles.active : ""}`}
            >
              <div
                id={`${mobileStyles.buses_vtl_mb}`}
                className={`${styles.dashed_lines} ${activeId === "buses" ? styles.active : ""}`}
              >
                <div className={styles.detail_box} id={mobileStyles.buses_details_mb}>
                  Tracking available for all passengers, including real time ETAs
                  for bus stops as well as automated attendance counting
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UseCases;
