import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import XBLCard from "../../helper_components/xbl_card";
import Particle from "../../helper_components/particle";
import styles from "./styles.module.css";
import Title from "../../helper_components/title";

const MetricsBoxes = () => {
  const containerRef = useRef(null);
  const mobileContainerRef = useRef(null);
  const desktopTitleRef = useRef(null);
  const mobileTitleRef = useRef(null);

  // Track scroll progress for desktop and mobile titles
  const { scrollYProgress: desktopTitleScroll } = useScroll({
    target: desktopTitleRef,
    offset: ["start center", "end start"],
  });
  const { scrollYProgress: mobileTitleScroll } = useScroll({
    target: mobileTitleRef,
    offset: ["start center", "end start"],
  });

  // Track scroll progress for containers
  const { scrollYProgress: desktopScrollY } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"],
  });
  const { scrollYProgress: mobileScrollY } = useScroll({
    target: mobileContainerRef,
    offset: ["start end", "end start"],
  });

  // Smoothly fade out title as it scrolls
  const desktopTitleOpacity = useTransform(desktopTitleScroll, [0, 0.9], [1, 0]);
  // const mobileTitleOpacity = useTransform(mobileTitleScroll, [0, 0.2], [1, 0]);

  // Define synchronized parallax transforms for desktop
  const translateYDesktop = [
    useTransform(desktopScrollY, [0, 0.5, 1], [1000, 50, -100]),
    useTransform(desktopScrollY, [0, 0.5, 1], [1000, -50, -150]),
    useTransform(desktopScrollY, [0, 0.5, 1], [1000, 50, -100]),
    useTransform(desktopScrollY, [0, 0.5, 1], [1000, -50, -200]),
  ];

  // Define synchronized parallax transforms for mobile
  const translateYMobile = [
    useTransform(mobileScrollY, [0, 0.8, 1], [400, 0, -300]),
    useTransform(mobileScrollY, [0, 0.8, 1], [400, 0, -200]),
  ];

  const boxData = [
    { main: "50%", body: "Improvement On Budget Accuracy Reported By A Fortune 500 Company" },
    { main: "10%", body: "Of Total Costs Saved By A Shipper With $100m In Ocean And Air Freight" },
    { main: "3%", body: "Gross Margin Increase Reported By Forwarders Using Xeneta During Tenders" },
    { main: "10x", body: "Roi Achieved In 6 Months Using Xeneta's Air Solutions" },
  ];

  return (
    <>
      {/* Desktop Container */}
      <div ref={containerRef} className={styles.metrics_container}>
        <motion.div
          ref={desktopTitleRef}
          style={{ opacity: desktopTitleOpacity }}
          className={styles.heading}
        >
          <Title text="The @Lynkit @XBL Advantage" />
        </motion.div>
        {boxData.map(({ main, body }, i) => (
          <motion.div key={i} style={{ y: translateYDesktop[i] }}>
            <XBLCard main={main} body={body} />
          </motion.div>
        ))}
        <div className={styles.particle_bg}>
          <Particle />
        </div>
      </div>

      {/* Mobile Container */}
      <div ref={mobileContainerRef} className={styles.mobile_metrics_container}>
        <motion.div
          ref={mobileTitleRef}
          // style={{ opacity: mobileTitleOpacity }}
          className={styles.heading}
        >
          <Title text="The @Lynkit @XBL Advantage" />
        </motion.div>
        <motion.div
          style={{ y: translateYMobile[0] }}
          className={styles.mb_first_col}
        >
          <div>
            <XBLCard main={boxData[0].main} body={boxData[0].body} />
          </div>
          <div>
            <XBLCard main={boxData[2].main} body={boxData[2].body} />
          </div>
        </motion.div>
        <motion.div
          style={{ y: translateYMobile[1] }}
          className={styles.mb_second_col}
        >
          <div>
            <XBLCard main={boxData[1].main} body={boxData[1].body} />
          </div>
          <div>
            <XBLCard main={boxData[3].main} body={boxData[3].body} />
          </div>
        </motion.div>
        <div className={styles.particle_bg}>
          <Particle />
        </div>
      </div>
    </>
  );
};

export default MetricsBoxes;
