import React from "react";
import styles from "./styles.module.css";
import Title from "../title";
import BodyText from "../body_text";
import {motion} from "framer-motion";

const XBLCard = ({main, body}) => {

  const radialBg = `radial-gradient(circle 150px, #ff77009c, #000)`;

  return (
    <div className={styles.upperbox_allSide_border}>
      <div className={styles["xbl_card_outer"]}>
        <div className={styles["xbl_inner_border"]} style={{"--bg" : radialBg}}>
          <div className={styles.xbl_inner_background} style={{"--bg" : radialBg}} />
          <div className={styles.content}>
            <Title text={main} extraClass={styles.metric_title} />
            <BodyText text={body} extraClass={styles.mt_body_text} />
          </div>
        </div>
        <div className={styles["xbl_outer_border"]}></div>

        {/* squares at each corner  */}
        <div className={`${styles.xbl_corner} ${styles["xbl_top-left"]}`}></div>
        <div className={`${styles.xbl_corner} ${styles["xbl_top-right"]}`}></div>
        <div className={`${styles.xbl_corner} ${styles["xbl_bottom-left"]}`}></div>
        <div className={`${styles.xbl_corner} ${styles["xbl_bottom-right"]}`}></div>

        <div className={styles.xbl_connecting_lines}>
          {/* Bottom connecting lines  */}
          <div className={styles.xbl_bottom_left_to_top_right}></div>
          <div className={styles.xbl_bottom_right_to_top_left}></div>
        </div>
      </div>
    </div>
  );
};

export default XBLCard;
