import React from 'react';
import styles from "./style.module.css";
import animStyles from "./anim.module.css";

const Particle = () => {
  const particles = Array.from({ length: 100 });
  return (
    <div className={styles["bg-animation"]}>
      <div id={styles['stars']}></div>
      <div id={styles['stars2']}></div>
      <div id={styles['stars3']}></div>
      <div id={styles['stars4']}></div>
    </div>
    
  )
}

export default Particle