import React, { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Accordian.css";

const skillsData = [
	{
		title: "If you are an importer paying for your freight costs",
		description: `Lynkit XBL helps you compare your freight rates with current market rates, so you know if you're paying too much. It also gives you details about surcharges and trends to plan your shipping costs better. \n\nEx: A shipping company decides to quote you an additional $1500 for surcharges due to a strike. You notice that market spot rates have gone up only by $1000. This indicates that customers aren't agreeing to the quoted escalation, but only 66% of it. `,
	},
	{
		title: "If you are an importer not paying for your freight costs",
		description: `Even if you don’t pay for shipping directly, Lynkit XBL lets you see if your suppliers are charging fair rates. It helps you negotiate better terms and avoid unnecessary extra costs and lower your landed prices, regardless of having ex-works or CIF/CFR contracts! \n\nEx: Your CFR prices have been increased by 10% by your supplier due to increased shipping costs. However you notice that shipping costs have actually gone down by 5% since your last order! Your supplier may just be trying to increase his margins by taking advantage of your perceived lack of knowledge on the freight market.`,
	},
	{
		title: "If you are an exporter paying for your freight costs",
		description: `Lynkit XBL gives you real-time data on freight rates and trends. You can use this information to choose cost-effective options, avoid higher charges during peak seasons, and save money. When buyers look at a price, they look at landed prices. This could be just what you need to be making your products more accessible to global markets. \n\nEx: You have lowered production costs by 5% as compared to a competing nation but your sales are still falling. It could be due to the fact that logistics cost from the alternate supplying nation are 10% lower as compared to yours resulting in a net 5% advantage when buying from the foreign competitor.  \n\nSimilarly, through our data if you can see trends to predict whether prices will continue to fall or rise. This could play a crucial role in deciding whether you need to be increasing or decreasing your raw material stocks! `,
	},
	{
		title: "If you are an exporter not paying for your freight costs",
		description: `Even if your buyer pays the shipping costs, Lynkit XBL helps you track changes in the market. High freight costs could be a reason why your products have lost international demand, even though your ex-works pricing may be lower than your competitor’s in another country. Intelligent pricing like this can help you understand demand patterns even better and thus take better decisions regarding inventory and stock levels. \n\nEx: You have lowered production costs by 5% compared to a competing nation, but your sales are still falling. It might make sense to ask your buyers what they are paying to their forwarders or liners. The forwarder might have better pricing for helping their customers import from another country, which could result in a net gain when your customer compares landed prices. You could negotiate with your buyers to provide them with CFR/CIF rates, which may actually beat the other competitors! \n\nSimilarly, through our data if you can see trends to predict whether prices will continue to fall or rise. This could play a crucial role in deciding whether you need to increase or decrease your raw material stocks!`,
	},
	{
		title: "Discovering Sales Patterns and Trends",
		description: `With Lynkit XBL, powered by insights like those from Xeneta, you can track market data to identify trends and predict fluctuations in sales. By analyzing shifts in freight rates across different trade lanes, you can gain insights into how these changes impact demand in specific regions. This understanding allows you to adapt your sales strategies to market opportunities effectively. \n\nFor instance, an unexpected spike in freight rates on a specific lane could indicate a temporary surge in demand, signaling a chance to increase sales efforts in that region. Conversely, a steady rate drop might imply reduced demand or an oversupply situation. Such intelligence empowers you to make timely adjustments to inventory, pricing, and sales efforts to maximize profitability. \n\nEx: Imagine freight rates from Southeast Asia to Europe suddenly drop due to an increase in fleet availability. This could create a window for cost-effective shipping and prompt increased stocking or aggressive sales campaigns in European markets. Similarly, data indicating long-term rate hikes could help forecast a slowdown in sales in regions sensitive to price changes`,
	},
	{
		title: " Picking the right shipping partners",
		description: `With Lynkit XBL, you gain access to robust market intelligence that allows you to benchmark supplier costs, be it from liners or forwarders, against global market rates. Beyond just pricing, evaluate the performance and reliability of your carriers, ensuring you select partners who offer competitive rates and dependable service. By leveraging real-time data, you eliminate the guesswork and reliance on limited contacts, streamlining your procurement process and maximizing operational efficiency. Gone are the times where you need to call your limited phonebook to understand market rates and trends. \n\nEx: Your company has been using a forwarder for years without assessing their pricing competitiveness. With Lynkit XBL, you discover that this forwarder charges 15% higher than the market average for a specific route. Additionally, data shows that another forwarder, offering rates closer to the market median, has a better on-time delivery rate. This insight helps you negotiate better terms or switch to a forwarder offering improved service and cost efficiency`,
	},
	{
		title: "Picking the right distribution centers and warehouses locally",
		description: `When deciding where to set up and find distribution centers for large EXIM-related operations, you ideally want them to be close to your chosen ports, airports, and ICDs. The freight price calculator will give you important insights to make meaningful and strategic decisions about your business’s needs. \n\nEx: A business importing raw materials can take a call to place their manufacturing hub or DC close to a port which offers a significantly lower cost of import and terminal handling charge bring down the overall landed cost`,
	},
	{
		title: "Supply chain and logistics planning",
		description: `Lynkit XBL helps you understand freight rate trends and plan your shipping routes and budgets. This allows you to avoid unexpected costs or delays and keep your logistics running smoothly. You can now factor in price spikes and dips into your budgets as well as make important assessments and comparisons of your carriers and forwarders. \n\nEx: You have an important shipment coming up and need to calculate whether you can stay within budget or what kind of price contract you should offer your customer in case they want freight cost built in. By analyzing long-term freight rate trends, you can decide which routes to take, whether to negotiate with new forwarders or carriers, and how to prepare your budget for any unexpected price fluctuations. This foresight ensures smooth and cost-efficient operations while preventing last-minute surprises.`,
	},
	{
		title: "Pick the right global suppliers",
		description: `As a buyer, you need to make important decisions about sourcing—whether you should do local procurement or import. If you import, then from where? In certain materials, freight cost can be over 25% of the product value or only 10%, depending on where you import from, and this is where freight pricing will play a critical role in deciding where your global suppliers should be. \n\nEx: A product costing $10 will cost $4.5 to ship from the country of origin. However, another country is providing a similar product at $11, but shipping will cost only $1.5. `,
	},
];

const ExpandIcon = ({ isOpen }) => (
	<motion.svg width="32" height="32" viewBox="0 0 24 24" className="ml-4" initial={{ rotate: 0 }} animate={{ rotate: isOpen ? 90 : 0 }} transition={{ duration: 0.5, ease: "easeInOut" }}>
		<line x1="5" y1="12" x2="19" y2="12" stroke="currentColor" strokeWidth="1.5" />
		<line x1="12" y1="5" x2="12" y2="19" stroke="currentColor" strokeWidth="1.5" />
	</motion.svg>
);

const Accordian = () => {
	const [activeIndex, setActiveIndex] = useState(null); // Track only one active accordion

	const toggleAccordion = (index) => {
		setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); // Only one accordion open at a time
	};

	return (
		<div className="accordion-container">
			<div className="skills">
				{skillsData.map((skill, index) => (
					<div key={index} className={`accordion-items ${activeIndex === index ? "open" : ""}`}>
						<div className="accordion-header" onClick={() => toggleAccordion(index)} aria-expanded={activeIndex === index} aria-controls={`panel-${index}`}>
							<div className="index-title">
								<span className="title">{skill.title}</span>
							</div>
							<ExpandIcon isOpen={activeIndex === index} />
						</div>
						<AnimatePresence initial={false}>
							{activeIndex === index && (
								<motion.div
									id={`panel-${index}`}
									className="accordion-panel"
									initial={{ height: 0, opacity: 0 }}
									animate={{
										height: "auto",
										opacity: 1,
									}}
									exit={{
										height: 0,
										opacity: 0,
									}}
									transition={{ duration: 0.5, ease: "easeInOut" }}
								>
									<p>
										{skill.description.split("\n").map((line, i) => (
											<span key={i}>
												{line}
												<br />
											</span>
										))}
									</p>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				))}
			</div>
		</div>
	);
};

export default Accordian;
