import { useState } from "react";
import axios from "axios";

const useApi = (showToast) => {
  const [loading, setLoading] = useState(false);

  const callApi = async ({ url, method = "POST", data = null, config = {} }) => {
    setLoading(true);
    try {
      const response = await axios({ url, method, data, ...config });

      if (response?.data?.success) {
        showToast(response?.data?.message, "success");
        return { success: true, data: response.data?.data };
      } else {
        const errorMessage = response?.data?.message || "An error occurred.";
        showToast(response?.data?.message, "error");
        throw new Error(errorMessage);
      }
    } catch (error) {
      const message = error.response?.data?.message || "An error occurred.";
      showToast(message, "error");
      return { success: false, error: message };
    } finally {
      setLoading(false);
    }
  };

  return { callApi, loading };
};

export default useApi;
