import React from 'react';
import styles from "./styles.module.css";

const BodyText = ({text, variant="medium", extraStyles = {}, extraClass}) => {
  return (
    <p className={`${styles.body_text} ${styles[variant]} ${extraClass}`} style={{...extraStyles}}>
      {text}
    </p>
  )
}

export default BodyText