import React, { useRef, useState, useEffect, useCallback } from 'react';
import styles from "./styles.module.css";
import { motion, useScroll, useTransform } from "framer-motion";
import Title from '../../helper_components/title';
import Particle from '../../helper_components/particle';

const Example = () => {
	return (
		<div style={{ backgroundColor: "#000", position: "relative" }}>
			<HorizontalScrollCarousel />
		</div>
	);
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const [activeCard, setActiveCard] = useState(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

	const isSmallScreen = () => {
		return window?.matchMedia("(max-width: 800px)")?.matches;
	}

  const x = useTransform(scrollYProgress, [0, 1], [isSmallScreen() ? '1%' : '7%', isSmallScreen() ? '-85%' : `-70%`]); // Adjust transform range
  
  const cardsRef = useRef([]);

	const setCardRef = useCallback((cardId) => (el) => {
    if (el) {
      cardsRef.current[cardId] = el;
    } else {
      cardsRef.current[cardId] = null;
    }
  }, []);

  // Set up Intersection Observer to track the closest card
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const card = entry.target;
        const cardId = card.getAttribute("data-id");

        if (entry.isIntersecting) {
          setActiveCard(cardId); // Set the active card when it is in view
        }
      });
    }, { threshold: 1 }); // Track when 50% of the card is in view

    // Observe all the cards
    cardsRef.current.forEach((card) => {
			if(card){
				observer.observe(card);
			}
    });

    return () => {
      // Clean up observer on component unmount
      cardsRef?.current?.forEach((card) => {
				if(card){
					observer?.unobserve(card);
				}
      });
    };
  }, []);
	

  return (
    <section ref={targetRef} className={styles.business_container}>
      <div className={styles.sticky_container}>
				<div className={styles.particle_bg}>
					<Particle />
				</div>
				<div style={{maxWidth: "1000px", zIndex: 3}}>
					<Title text = "Why Businesses in India Should Choose @Lynkit @XBL" />
				</div>
        <motion.div className={styles.content_wrapper} style={{ x }}>
          {cards.map((card) => {
						return (
							<Card
								key={card.id}
								card={card}
								activeCard={activeCard}
								isActive={activeCard === card.id}
								ref={setCardRef(card.id)} // Attach ref to each card
            	/>
						)
					})}
        </motion.div>
      </div>
    </section>
  );
};

const Card = React.forwardRef(({ card, isActive, activeCard, ...props }, ref) => {
	
  return (
    <div
      {...props}
      ref={ref}
      data-id={card.id} // Use card ID to track it in the intersection observer
			className={`${styles.content_box} ${activeCard == card?.id ? styles.active_card : ""}`}
    >
      <span className={styles.card_title}>{card?.title}</span>
			<span className={styles.card_body}>{card?.body}</span>
    </div>
  );
});

const cards = [
	{
		title: "Affordable & Accessible Freight Intelligence",
		body: "Lynkit XBL provides businesses with affordable access to competitive freight market intelligence. Businesses can leverage critical freight data without requiring large-scale investments through flexible access to key lanes. This includes comparing rates across various lanes and understanding market trends for smarter decisions and greater efficiency.",
		id: 1,
	},
	{
		title: "Data-Driven Decision Making",
		body: "Lynkit XBL allows businesses to benchmark their freight rates against real-time market data, ensuring they make informed, data-backed decisions. This is crucial whether negotiating long-term contracts or responding to short-term opportunities. Using up-to-date data, businesses can better manage their supply chains and procurement strategies. Xeneta’s benchmarking allows companies to compare their rates against global averages, ensuring cost competitiveness and better decision-making.",
		id: 2,
	},
	{
		title: "Cost Optimization",
		body: "Lynkit XBL platform gives businesses visibility into surcharges and market trends, enabling optimization of their shipping costs. By benchmarking their freight rates against current market data, businesses can identify opportunities to save money and ensure they are securing the most competitive rates.",
		id: 3,
	},
	{
		title: "Competitive Advantage",
		body: "Lynkit XBL helps businesses stay ahead of competitors by enabling them to react faster to market changes. With real-time insights, businesses can better adjust their procurement strategies to secure competitive rates. By utilizing Xeneta’s data-driven approach, companies can remain competitive in dynamic markets by adapting quickly to supply chain shifts and optimizing costs.",
		id: 4,
	},
	{
		title: "Prepare for Market Disruptions",
		body: "With Lynkit XBL, businesses can anticipate and respond to market disruptions early. By monitoring market shifts, including demand fluctuations, changes in regulations, and rate adjustments, businesses are equipped to adjust their strategies proactively. Our platform offers insights that allow businesses to anticipate market changes and mitigate the impact of unexpected disruptions on their operations and bottom line.",
		id: 5,
	},
];


const Business = () => {
	const targetRef = React.useRef(null);
	const { scrollYProgress } = useScroll({
		target: targetRef,
	});

	const x = useTransform(scrollYProgress, [0, 1], ["1%", "-95%"]);

	const cards = [
		{
			id: 1,
			title: "Mountain",
			url: "https://example.com/mountain.jpg",
		},
		{
			id: 2,
			title: "Ocean",
			url: "https://example.com/ocean.jpg",
		},
		{
			id: 3,
			title: "Forest",
			url: "https://example.com/forest.jpg",
		},
		{
			id: 4,
			title: "Desert",
			url: "https://example.com/desert.jpg",
		},
	];

	return (
		// <div ref={targetRef} className={styles.business_container}>
		// 	<div className={styles.inner_fixed} >
		// 		<div className={styles.content}>
		// 			<div className={styles.content_card}>
		// 				1
		// 			</div>
		// 			<div className={styles.content_card}>
		// 				2
		// 			</div>
		// 			<div className={styles.content_card}>
		// 				3
		// 			</div>
		// 			<div className={styles.content_card}>
		// 				4
		// 			</div>
		// 			<div className={styles.content_card}>
		// 				5
		// 			</div>
		// 			<div className={styles.content_card}>
		// 				6
		// 			</div>
		// 		</div>
		// 	</div>
		// </div>

		<Example />
	)
}

export default Business