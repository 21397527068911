import React from 'react';
import styles from "./styles.module.css";
import abf from "../../assets/clients/ABF_logo.svg";
import bat from "../../assets/clients/BAT_logo.svg";
import ceva from "../../assets/clients/ceva_logo.svg";
import coca_cola from "../../assets/clients/coca_cola-logo.svg";
import KN from "../../assets/clients/KN_logo.svg";
import loreal from "../../assets/clients/loreal_logo.svg";
import nestle from "../../assets/clients/nestle_logo.svg";
import unilever from "../../assets/clients/unilever_logo.svg";
import volvo from "../../assets/clients/volvo_logo.svg";
import Title from '../../helper_components/title';

const Clients = () => {

  const logos = [
    abf,
    bat,
    ceva,
    coca_cola,
    KN,
    loreal,
    nestle,
    unilever,
    volvo,
  ];

  return (
    <div className={styles.client_container}>
      <div className={styles.text_content_outer}>
        <p className={styles.info}>
          <b>An advanced freight</b> intelligence solution for shippers and freight forwarders in India. With Lynkit XBL, you can navigate the complexities of ocean and air freight procurement, optimize your rates, and make smarter, data-driven decisions to save time and reduce costs.
        </p>
        <div className={styles.divider} />
        <Title text="The Who's who is using @Xeneta @Powered @Data" />
      </div>

      <div className={styles.ticker_wrapper}>
        <div className={styles.ticker}>
          {[...logos, ...logos].map(
            (
              logo,
              index // Single duplication for seamless loop
            ) => (
              <div className={styles["ticker-item"]} key={index}>
                <img src={logo} alt={`logo-${index}`} style={index % 2 === 0 ? { height: "75px" } : {}} />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default Clients