import React from 'react';
import hero_bg from '../../assets/hero/xbl_hero_bg.webp';
import hero_fg from '../../assets/hero/xbl_hero_foreground.webp';
import styles from "./styles.module.css";
import Title from '../../helper_components/title';
import Layout from './Layout';
import hero_mb_img1 from '../../assets/hero/hero_mb_img1.svg'
import {motion} from "framer-motion";

const BubbleText = ({lines}) => {
  return(
    <div className={styles.bt_container}>
      {lines.map((line, idx) => (
        <div key={idx}>
          {line.split("").map((char, charIdx) => (
            <span className={styles.hoverText} key={charIdx}>
              {char}
            </span>
          ))}
        </div>
      ))}
    </div>
  )
}

const Hero = () => {

  const lines = [
    "Transform",
    "Your",
    "Freight",
    "Procurement",
    "with",
    "Lynkit XBL",
  ]

  return (
    <Layout>
      <div className={styles.hero_container}>
        <img src={hero_bg} className={styles.bg_img} />
        <div className={styles.content_img_mb_box}>
          <img src={hero_mb_img1} />
        </div>
        <div className={styles.content_container}>
          {/* <span className={styles.titleText}>Transform <br />Your <br /> Freight <br />Procurement <br /> with XBL <br />  Lynkit</span> */}
          
          <BubbleText lines={lines} />
          
          <div className={styles.content_img_box}>
            <motion.img 
              src={hero_fg} 
              initial={{opacity: 0, x: 200}}
              animate={{opacity: 1, x: 0}}
              transition={{type: "spring", stiffness: 100, delay: 0.8, duration: 0.4}}
            />
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default Hero