import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css"; // Navigation styles
import styles from "./Slider.module.css";
import SwiperCore, { Navigation } from "swiper";

// Enable Swiper navigation module
SwiperCore.use([Navigation]);

const CustomSlider = ({slides}) => {
  return (
    <div className={styles.customSlider_container}>
      <Swiper
        slidesPerView={3} // Default: 3 slides visible
        loop={true}
        // spaceBetween={20} // Space between slides
        freeMode={true} // Enable FreeMode
        centeredSlides={false} // Ensure slides start at the left edge
        navigation={{
          prevEl: `.${styles.customSlider_prevButton}`,
          nextEl: `.${styles.customSlider_nextButton}`,
        }}
        breakpoints={{
          1500: {
            slidesPerView: 4, // Big screens: 3 slides
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3, // Big screens: 3 slides
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 2.5, // Slightly show the next slide
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2, // Tablets: 2 slides
            spaceBetween: 10,
          },
          250: {
            slidesPerView: 1, // Mobile: 1 slide
            spaceBetween: 10,
          },
        }}
        className={styles.customSlider_swiper}
      >
        {/* Slides */}
        {slides?.map((slide, i) => {
          return (
            <SwiperSlide key={i} className={styles.customSlider_slide}>
              <div className={styles.testimonial_card}>
                <div className={styles.quote}>"{slide.quote}"</div>
                <div className={styles.companydetails}>
                  <div className={styles.namePosition}>
                    <div className={styles.name}>{slide.name}</div>
                    <div className={styles.position}>{slide.position}</div>
                  </div>
                  <img src={slide.logo} className={styles.logo} />
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>

      {/* Custom Navigation Buttons */}
      <button
        className={`${styles.customSlider_navButton} ${styles.customSlider_prevButton}`}
      >
        &#8249; {/* Left arrow */}
      </button>
      <button
        className={`${styles.customSlider_navButton} ${styles.customSlider_nextButton}`}
      >
        &#8250; {/* Right arrow */}
      </button>
    </div>
  );
};

export default CustomSlider;
